.query {
  background-color: rgb(8 6 36);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: top;
  align-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
  gap: 24px;
  padding: 48px;
}

.query_begin_input_div {
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.query_end_input_div {
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.query_button {
  padding: 6px;
  display: flex;
  width: 120px;
  height: 3em;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  border: none;
}