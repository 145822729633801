.tweet-single {
  background-color: rgb(8 6 36);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 24px;
  width: min(500px, 70vw);
  background: linear-gradient(rgb(8 6 36), rgb(8 6 36)) padding-box,
    linear-gradient(99deg, rgb(64, 34, 71) 44.26%, rgb(167, 66, 230) 69.14%, rgb(38, 34, 71) 96.8%) border-box;
  border-radius: 1.5rem;
  border: 2px solid transparent;
}

@media screen and (min-width: 1024px) {
  .tweet-single {
    width: min(500px, 40vw);
  }
}

.tweet-single>div {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  padding: 12px;
  font-size: 80%;
}

.tweet-date {
  text-align: left;
  color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60%;
}

.s3link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 2em;
  width: auto;
}

a.social-icon {
  height: 1em !important;
  width: 1em !important;
}

.user-link {
  color: gray;
}

.tweet-single>div.hashtags,
.hashtag {
  color: gray;
  gap: 8px;
  justify-content: start;
  align-items: flex-start;
}

.tweet-single>div.tweet-text {
  font-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download {
  background-color: transparent;
  color: white;
  font-size: larger;
  border: none;
}

.download:hover {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

/* pallette {
  background-color: #16213E;
  background-color: #0F3460;
  background-color: #533483;
  background-color: #E94560;
  background-color: #D8D8D8;
  background-color: #F7F7F7;
} */